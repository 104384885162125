import React from 'react'
import { Link } from 'gatsby'

import "../css/main.sass"
import style from "../css/pages-css/blog.module.sass"

import Layout from '../components/Layout'
import BlogFeed from '../components/BlogFeed'

const Blog = ({data}) => (
  <Layout>
    <main className={style.main + " standard-page"}>
      <section className={style.welcomeSection}>
        <h1 className={" fw-black big"}>Blog</h1>
      </section>
      <section className={style.blogFeedSection}>
        <BlogFeed graphqldata={data}></BlogFeed>
      </section>
    </main>
  </Layout>
)

export default Blog

export const query = graphql`
query{
    allDirectory(filter: {dir: {eq: "C:/Users/user/Desktop/PersonalSite/PersonalSite/src/pages/blog"}}){
        edges{
          node{
            name
          }
        }
    }
}
`
